<template>
  <div>
    <div v-if="!!label" class="label">
      {{ label }}
    </div>
    <div class="select-wrapper">
      <select
        :class="selectClasses"
        :value="value"
        @change="handleSelectChange"
      >
        <option
          v-if="!!placeholder"
          value=""
          disabled
          :selected="value === null"
        >
          {{ placeholder }}
        </option>
        <option
          v-for="option in options"
          :value="option.value"
          :key="option.value"
        >
          {{ option.label }}
        </option>
      </select>
      <img class="icon" src="../assets/arrow-down.svg" @click.stop />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    placeholder: String,
    value: [String, Number, null],
    options: Array,
  },
  computed: {
    selectClasses() {
      const classes = ["select"];
      if (this.value === null) {
        classes.push("select-null");
      }
      return classes;
    },
    formattedValue() {
      return this.value === null ? "" : this.value;
    },
  },
  methods: {
    handleSelectChange(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>

<style scoped>
.label {
  color: #fff;
  font-size: 14px;
  margin-bottom: 4px;
}

.select-wrapper {
  position: relative;
}

.select {
  background-color: #ffffff0a;
  height: 42px;
  border: 1px solid #ffffff1a;
  border-radius: 8px;
  line-height: 42px;
  padding-left: 16px;
  font-size: 14px;
  display: block;
  width: 100%;
  color: #fff;
}

.select:focus {
  outline: none;
}

.select-null {
  color: #ffffff60;
}

.select option {
  color: #000;
}

.icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 768px) {
  .select {
    line-height: 20px;
    padding-left: 8px;
    font-size: 12px;
    text-overflow: ellipsis;
    padding-right: 21px;
  }
}
</style>
